import React from "react";

import ResearchList from "../components/ResearchList";
import ResearchSignUpForm from "../components/ResearchSignUpForm";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/_research.scss";
import ogImage from "../images/OG_Image.png";

const ResearchPage = () => (
  <Layout>
    <SEO
      title="Research"
      ogImage={ogImage}
      keywords={[
        "Flowspring",
        "research",
        "latest",
        "in-depth",
        "rigorous",
        "asset management",
        "competitive intelligence",
        "analytics",
        "fee optimization",
        "fund flows",
      ]}
      description="Read Flowspring's latest research to truly understand the landscape and trends of the asset management industry. Our differentiated point of view will help you cut through the noise and understand what's important."
    />
    <div id="researchContent">
      <ResearchList />
      <ResearchSignUpForm />
    </div>
  </Layout>
);

export default ResearchPage;
