import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { nanoid } from "nanoid";

const ResearchList = () => {
  const query = graphql`
    {
      allContentfulResearch(sort: { fields: [createdAt], order: DESC }) {
        edges {
          node {
            id
            slug
            title
            subtitle {
              subtitle
            }
            shortSummary {
              shortSummary
            }
            summaryImage {
              id
              file {
                url
              }
            }
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={data => {
        const items = data.allContentfulResearch.edges.map((e) => (
          <Link to={`/research/${e.node.slug}`}>
            <div key={nanoid()} className="researchItem">
              <img
                src={e.node.summaryImage.file.url}
                style={{ width: 320, height: 160 }}
              />
              <div className="researchTextHolder">
                <div className="researchTitle">{e.node.title}</div>
                <div className="researchSubTitle">
                  {e.node.subtitle.subtitle}
                </div>
                <div className="researchSummary">
                  {e.node.shortSummary.shortSummary}
                </div>
              </div>
            </div>
          </Link>
        ));

        return <>{ items }</>;
      }}
    />
  );
};

export default ResearchList;
