import React, { useState, useEffect } from "react";

const ResearchSignUpForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsSubmitted(false), 2000);
  });

  const handleSubmit = e => {
    e.preventDefault();
    fetch(
      "https://hhx3pthj85.execute-api.us-west-2.amazonaws.com/prod/handleresearchdistributionsignup",
      {
        method: "POST",
        body: JSON.stringify({
          email: e.target.email.value,
        }),
      },
    )
      .then(response => response.json())
      .then(() => { setIsSubmitted(true); });
  };

  return null;
  /*
  return (
    <>
      {isSubmitted && (
        <div id="alert">Thanks for joining our research distribution list!</div>
      )}
      <form
        id="researchDistributionSignupContainer"
        onSubmit={handleSubmit}
        method="post"
      >
        <div>
          <div id="title">Sign up for our research distribution list</div>
          <div>Receive our research as soon as we publish.</div>
        </div>
        <div id="signupInputContainer">
          <input
            type="text"
            placeholder="Email"
            name="email"
            autoComplete="email"
          />
          <input type="submit" />
        </div>
      </form>
    </>
  );

   */
};

export default ResearchSignUpForm;
